import { render, staticRenderFns } from "./FlowText.vue?vue&type=template&id=38e1c11a&"
import script from "./FlowText.vue?vue&type=script&lang=js&"
export * from "./FlowText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArrowLink: require('/vercel/path0/components/general/ArrowLink.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default})
